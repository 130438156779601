<template>
  <div class="char">
    <div class="modal fade" ref="resolveModal" tabindex="-1">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Gear Details</h5>
            <button type="button" class="btn-close" @click="modal.hide()"></button>
          </div>
          <div class="modal-body">
            <table class="gear">
              <GearItem title="Cloak" itemSlot="cloak" :gear="char.gear"></GearItem>
              <GearItem title="Head" itemSlot="head" :gear="char.gear"></GearItem>
              <GearItem title="Shoulders" itemSlot="shoulders" :gear="char.gear"></GearItem>
              <GearItem title="Chest" itemSlot="chest" :gear="char.gear"></GearItem>
              <GearItem title="Forearms" itemSlot="forearms" :gear="char.gear"></GearItem>
              <GearItem title="Hands" itemSlot="hands" :gear="char.gear"></GearItem>
              <GearItem title="Legs" itemSlot="legs" :gear="char.gear"></GearItem>
              <GearItem title="Feet" itemSlot="feet" :gear="char.gear"></GearItem>
              <GearItem title="Primary" itemSlot="primary" :gear="char.gear"></GearItem>
              <GearItem title="Secondary" itemSlot="secondary" :gear="char.gear" v-if="!usingTwoHander()"></GearItem>
              <GearItem title="Ranged" itemSlot="ranged" :gear="char.gear"></GearItem>
              <GearItem title="Charm" itemSlot="activate1" :gear="char.gear"></GearItem>
              <GearItem title="Charm" itemSlot="activate2" :gear="char.gear"></GearItem>
              <GearItem title="Left Ear" itemSlot="ears" :gear="char.gear"></GearItem>
              <GearItem title="Right Ear" itemSlot="ears2" :gear="char.gear"></GearItem>
              <GearItem title="Neck" itemSlot="neck" :gear="char.gear"></GearItem>
              <GearItem title="Left Finger" itemSlot="left_ring" :gear="char.gear"></GearItem>
              <GearItem title="Right Finger" itemSlot="right_ring" :gear="char.gear"></GearItem>
              <GearItem title="Left Wrist" itemSlot="left_wrist" :gear="char.gear"></GearItem>
              <GearItem title="Right Wrist" itemSlot="right_wrist" :gear="char.gear"></GearItem>
              <GearItem title="Waist" itemSlot="waist" :gear="char.gear"></GearItem>
              <GearItem title="Event" itemSlot="event_slot" :gear="char.gear"></GearItem>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="modal.hide()">Close</button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h2 class="card-title"><a v-if="getCharLink" :href="getCharLink" target="_blank" rel="nofollow">{{ char.name }}</a> <span class="char-rank">{{ char.multiplier }}</span></h2>
      <div class="char-info">
        <div>{{ char.class }},
          <a v-if="getGuildLink" :href="getGuildLink" target="_blank" rel="nofollow">{{ char.guild }}</a>
          <span v-else>Unguilded</span>
        </div>
        <div><font-awesome-icon :class="char.mountTier" :icon="['fas', 'dove']" />
          <a v-if="getMountLink" :href="getMountLink" target="_blank" rel="nofollow">{{ char.mount }}</a>
          <span v-else>No mount</span>
        </div>
        <div><font-awesome-icon :class="char.familiarTier" :icon="['fas', 'frog']" />
          <a v-if="getFamiliarLink" :href="getFamiliarLink" target="_blank" rel="nofollow">{{ char.familiar }}</a>
          <span v-else>No familiar</span>
        </div>
        <div><font-awesome-icon :class="char.mercTier" :icon="['fas', 'user-nurse']" />
          <span>{{ char.merc }}</span>
        </div>
        <div v-if="char.checkup.messages.length > 0">
          <div v-for="(message, i) in char.checkup.messages" :key="i" class="checkup-message">
            <font-awesome-icon :icon="['fas', 'exclamation-circle']" />{{ message }}
          </div>
        </div>
        <div v-for="item in getGreaterRelicItems()" :key="item">
          <span class="greater-relic">Greater Relic: {{ item }}</span>
        </div>
        <div v-for="item in getRelicItems()" :key="item">
          <span class="relic">Relic: {{ item }}</span>
        </div>
      </div>
      <table>
        <tr>
          <th>Misc</th><th></th>
        </tr>
        <CharStatsItem statName="Cast Speed" :statValue="char.castSpeed.total"></CharStatsItem>
        <CharStatsItem statName="Reuse" :statValue="char.reuse.total"></CharStatsItem>
        <CharStatsItem statName="ADC" :statValue="char.adc.total"></CharStatsItem>
        <tr>
          <th>Resolve</th><th><a href="javascript:void(0);" @click="modal.show()">{{ char.resolve.total }}</a></th>
        </tr>
        <CharStatsItem v-for="item in sortStatItems([
          {n: 'Gear', v: char.resolve.gear},
          {n: 'Other', v: char.resolve.other}
          ])" :statName="item.n" :statValue="item.v" :key="'resolve' + item.n"></CharStatsItem>
        <tr>
          <th>Potency</th><th>{{ formatNumber(char.potency.total) }}</th>
        </tr>
        <CharStatsItem v-for="item in sortStatItems([
          {n: 'Mount', v: char.potency.mount},
          {n: 'Mount Buff', v: char.potency.mountBuff},
          {n: 'Familiar', v: char.potency.familiar},
          {n: 'Gear', v: char.potency.gear},
          {n: 'Adorns', v: char.potency.adorns},
          {n: 'Food/Drink', v: char.potency.foodDrink},
          {n: 'AAs', v: char.potency.aas},
          {n: 'Preorder Pet', v: char.potency.preorderPet},
          {n: 'Flawless', v: char.potency.flawless},
          {n: 'RoR Puppy', v: char.potency.rorPuppy},
          {n: 'Other', v: char.potency.other}
          ])" :statName="item.n" :statValue="item.v" :key="'potency' + item.n"></CharStatsItem>
        <tr>
          <th>Crit Bonus</th><th>{{ formatNumber(char.cb.total) }}</th>
        </tr>
        <CharStatsItem v-for="item in sortStatItems([
          {n: 'Gear', v: char.cb.gear},
          {n: 'Adorns', v: char.cb.adorns},
          {n: 'Merc', v: char.cb.merc},
          {n: 'Food/Drink', v: char.cb.foodDrink},
          {n: 'AAs', v: char.cb.aas},
          {n: 'Guild Buff', v: char.cb.guildBuff},
          {n: 'Divine Buff', v: char.cb.divineBuff},
          {n: 'Other', v: char.cb.other}
          ])" :statName="item.n" :statValue="item.v" :key="'cb' + item.n"></CharStatsItem>
        <tr>
          <th>Crit Bonus Overcap</th><th>{{ formatNumber(char.cbOvercap.total) }}</th>
        </tr>
        <CharStatsItem v-for="item in sortStatItems([
          {n: 'Base', v: char.cbOvercap.base},
          {n: 'Familiar', v: char.cbOvercap.familiar},
          {n: 'Mount', v: char.cbOvercap.mount},
          {n: 'Merc', v: char.cbOvercap.merc},
          {n: 'Gear', v: char.cbOvercap.gear},
          {n: 'Adorns', v: char.cbOvercap.adorns},
          {n: 'Food/Drink', v: char.cbOvercap.foodDrink},
          {n: 'AAs', v: char.cbOvercap.aas},
          {n: 'Flawless', v: char.cbOvercap.flawless},
          {n: 'Divine Buff', v: char.cbOvercap.divineBuff}
          ])" :statName="item.n" :statValue="item.v" :key="'cbOvercap' + item.n"></CharStatsItem>
        <tr>
          <th>Fervor</th><th>{{ char.fervor.total }}</th>
        </tr>
        <CharStatsItem v-for="item in sortStatItems([
          {n: 'Gear', v: char.fervor.gear},
          {n: 'Adorns', v: char.fervor.adorns},
          {n: 'Merc', v: char.fervor.merc},
          {n: 'Flawless', v: char.fervor.flawless},
          {n: 'Divine Buff', v: char.fervor.divineBuff},
          {n: 'Familiar Buff', v: char.fervor.familiarBuff},
          {n: 'Other', v: char.fervor.other}
          ])" :statName="item.n" :statValue="item.v" :key="'fervor' + item.n"></CharStatsItem>
        <tr>
          <th>Fervor Overcap</th><th>{{ char.fervorOvercap.total }}</th>
        </tr>
        <CharStatsItem v-for="item in sortStatItems([
          {n: 'Gear', v: char.fervorOvercap.gear},
          {n: 'Adorns', v: char.fervorOvercap.adorns},
          {n: 'Merc', v: char.fervorOvercap.merc},
          {n: 'Food/Drink', v: char.fervorOvercap.foodDrink},
          {n: 'Flawless', v: char.fervorOvercap.flawless},
          {n: 'Divine Buff', v: char.fervorOvercap.divineBuff},
          {n: 'Familiar Buff', v: char.fervorOvercap.familiarBuff}
          ])" :statName="item.n" :statValue="item.v" :key="'fervorOvercap' + item.n"></CharStatsItem>
      </table>
      <div v-for="colour in ['Purple', 'Cyan', 'Green', 'White', 'Black', 'Temporary']" :key="colour">
        <div v-if="colour != 'Temporary' || filterAdorns(colour).length > 0" class="adorn-list">
          <h4 class="adorn-type" :class="colour.toLowerCase()">
            <font-awesome-icon :icon="['far', 'sun']" /><span>
              {{ colour }}
              <span class="adorn-count">({{ countEquippedAdorns(colour) }} / {{ countAdornSlots(colour) }})</span>
            </span>
            <label>
              <input type="checkbox" @click="showAdornSlots = !showAdornSlots">
              <font-awesome-icon :icon="['fas', 'info-circle']" />
            </label>
          </h4>
          <ul>
            <li v-for="(item, index) in filterAdorns(colour)" :key="index">
              <font-awesome-icon v-if="!item.current" class="adorn-old" :icon="['fas', 'exclamation-triangle']" title="No effect in BoZ"/>
              <a :href="'https://u.eq2wire.com/item/index/' + item.id" target="_blank">{{ item.name }}</a>
              <span v-if="item.set" class="adorn-set">{{ item.set }}</span>
              <span class="adorn-slot" :class="{'shown': showAdornSlots}">{{ item.slot }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from 'bootstrap';
import GearItem from './GearItem';
import CharStatsItem from './CharStatsItem';

export default {
  name: 'CharStats',
  components: {
    GearItem,
    CharStatsItem
  },
  props: {
    char: Object
  },
  data: function() {
    return {
      showAdornSlots: false,
      modal: null,
    }
  },
  computed: {
    getCharLink: function() {
      if (this.char != null) {
        return `https://u.eq2wire.com/soe/character_detail/${this.char.id}`;
      }
      return "";
    },
    getGuildLink: function() {
      if (this.char?.guildId ?? null != null) {
        return `https://u.eq2wire.com/soe/guild_detail/${this.char.guildId}`;
      }
      return "";
    },
    getMountLink: function() {
      if (this.char?.mountId ?? null != null) {
        return `https://u.eq2wire.com/item/index/${this.char.mountId}`;
      }
      return "";
    },
    getFamiliarLink: function() {
      if (this.char?.familiarId ?? null != null) {
        return `https://u.eq2wire.com/item/index/${this.char.familiarId}`;
      }
      return "";
    },
    getButtonText() {
      return this.isFetching ? "Searching..." : "Search";
    },
    getButtonDisabled() {
      return this.isFetching;
    },
  },
  methods: {
    sortStatItems(items) {
      return items.sort((x, y) => {
        if (x.n == 'Other') return 1;
        if (y.n == 'Other') return -1;
        return (x.v < y.v ? 1 : -1);
      });
    },
    filterAdorns: function(colour) {
      const adorns = this.char?.adorns?.[colour.toLowerCase()] ?? [];
      return adorns;
    },
    usingTwoHander: function() {
      return this.char?.gear?.['primary']?.is2Hander ?? false;
    },
    countEquippedAdorns: function(colour) {
      const adorns = this.char?.adorns?.[colour.toLowerCase()] ?? [];
      return adorns.length;
    },
    countAdornSlots: function(colour) {
      colour = colour.toLowerCase();
      let count = 0;
      for (const item in this.char?.gear) {
        if (Object.prototype.hasOwnProperty.call(this.char.gear[item]?.adorns ?? {}, colour)) {
          count += this.char.gear[item].adorns[colour].count ?? 0;
        }
      }
      return count;
    },
    getGreaterRelicItems: function() {
      let items = [];
      for (const item in this.char?.gear) {
        if (this.char.gear[item]?.isGreaterRelic ?? false) {
          items.push(this.char.gear[item].name);
        }
      }
      for (const adornGroup in this.char?.adorns) {
        for (const adorn of this.char.adorns[adornGroup]) {
          if (adorn.isGreaterRelic ?? false) {
            items.push(adorn.name);
          }
        }
      }
      return items;
    },
    getRelicItems: function() {
      let items = [];
      for (const item in this.char?.gear) {
        if (this.char.gear[item]?.isRelic ?? false) {
          items.push(this.char.gear[item].name);
        }
      }
      for (const adornGroup in this.char?.adorns) {
        for (const adorn of this.char.adorns[adornGroup]) {
          if (adorn.isRelic ?? false) {
            items.push(adorn.name);
          }
        }
      }
      return items;
    },
    formatNumber: function(number) {
      return number >= 10000 ? (number).toLocaleString() : number;
    },
    addValueClasses: function(number) {
      return number <= 0 ? 'zero-val' : '';
    },
  },
  mounted: function() {
    this.modal = new Modal(this.$refs.resolveModal);
  }
}
</script>

<style lang="scss" scoped>
.char {
  margin-bottom: 0.75em;
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
h2 {
  margin: 1em 0 0 0.25em;
}
table {
  margin-top: 1em;
  th {
    background-color: #ddeeff;
  }
  th, td {
    padding: 0.3em 1em 0.3em 0.3em;
    text-align: left;
  }
}
.char-info {
  padding: 0.5em;
  svg {
    margin-right: 0.5em;
    color: #aaaaaa;
  }
}
.greater-relic {
  display: inline-block;
  margin: 0.1em 0;
  padding: 0 0.25em;
  font-size: 0.9em;
  border: 1px solid #530083;
  border-radius: 4px;
  background-color: #d181ff;
  color: #530083;
}
.relic {
  @extend .greater-relic;
  border: 1px solid #ad6800;
  background-color: #ffd477;
  color: #ad6800;
}
.checkup-message {
  color: #830000;
  svg {
    color: #830000;
  }
}
.char-rank {
  font-size: 0.8em;
  margin-left: 1.5em;
  padding: 0.125em 0.5em;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #f2f2f2;
  color: #222;
}
svg {
  opacity: 0.6;
  &.celestial {
    color: rgb(0, 155, 0);
  }
  &.fabled {
    color: rgb(189, 0, 0);
  }
  &.legendary {
    color: rgb(221, 181, 0);
  }
  &.treasured {
    color: rgb(0, 162, 221);
  }
}
.char-links {
  margin-bottom: 0.25em;
}
.adorn-list {
  margin: 1em 0.25em;
  padding: 1em;
  border: 1px solid #dddddd;
  border-radius: 3px;
  background-color: #fbfbfb;

  .adorn-type {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    svg:first-child {
      margin-right: 0.25em;
      opacity: 0.5;
    }
    &.white svg:first-child {
      color: lightgray;
    }
    &.black svg:first-child {
      color: black;
    }
    &.blue svg:first-child {
      color: blue;
    }
    &.red svg:first-child {
      color: red;
    }
    &.purple svg:first-child {
      color: purple;
    }
    &.cyan svg:first-child {
      color: cyan;
    }
    &.green svg:first-child {
      color: green;
    }
    &.temporary svg:first-child {
      color: lightblue;
      opacity: 0.8;
    }
    svg:not(:first-child) {
      color: #0d6efd;;
      opacity: 0.5;
      &:hover {
        opacity: 1.0;
      }
    }
    span {
      flex-grow: 1;
    }
    span.adorn-count {
      margin-left: 0.3em;
      font-size: 0.9em;
      color: #aaa;
    }
    input[type="checkbox"] {
      display: none;
    }
    label {
      cursor: pointer;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }
  li {
    margin: 0.25em 0;
    list-style: none;
    display: flex;
    align-items: center;
  }

  .adorn-set {
    opacity: 0.98;
    font-size: 0.9em;
    margin-left: 1em;
    margin-right: 0em;
    padding: 0em 0.5em;
    border: 1px solid #c00000;
    border-radius: 4px;
    background-color: #830000;
    color: white;
  }

  .adorn-slot {
    opacity: 0.98;
    font-size: 0.9em;
    margin-left: 0em;
    margin-right: 0.5em;
    padding: 0em 0.5em;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #f0f0f0;
    color: #222;
    display: none;
    order: -1;
    @media screen and (min-width: 900px) {
      order: 0;
      margin-left: 0.5em;
      margin-right: 0em;
    }
    &.shown {
      display: initial;
    }
  }

  .adorn-old {
    margin-right: 0.25em;
    color: #ce9e00;
  }
}
.gear {
  width: 100%;
  margin-top: 0;
}
.modal-dialog {
  max-width: 600px;
}
</style>
