<template>
  <tr :class="getHidden">
    <td class="gear-slot">
      <img :class="getImgClasses" :src="getIcon" onerror="this.src='data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='">
      <ul class="slots-purple">
        <li v-for="num in getPurples" :key="num"><a href="#">{{ num }}</a></li>
      </ul>
      <a :href="getId" target="_blank" v-if="hasItemEquipped()">
        <span>{{ getName }}</span>
      </a>
      <span v-else>{{ getName }}</span>
    </td>
    <td :class="getTier">
      {{ getResolve }}
      <a class="upgrade-link" v-if="getUpgradeLink" :href="getUpgradeLink" target="_blank">
        <font-awesome-icon :icon="['fas', 'level-up-alt']" />
      </a>
    </td>
  </tr>
</template>

<script>

export default {
  name: 'GearItem',
  props: {
    title: String,
    itemSlot: String,
    gear: Object
  },
  data: function() {
    return {
      showing: true
    }
  },
  computed: {
    getHidden() {
      if (this.itemSlot === 'event_slot' && !this.hasItemEquipped()) {
          return 'hidden';
      }
      return '';
    },
    getResolve() {
      return this.getResolveAmount();
    },
    getPurples() {
      return Array.from(Array(this.getPurpleCount()).keys());
    },
    getImgClasses() {
      var classes = [
        this.getTier,
        'gear-icon'
      ];
      if (this.hasCboc) {
        classes.push('cboc');
      }

      return classes.join(' ');
    },
    getTier() {
      if (this.hasItemEquipped()) {
        return this.gear[this.itemSlot].tier;
      }
      return '';
    },
    getId() {
      if (this.hasItemEquipped()) {
        return `https://u.eq2wire.com/item/index/${this.gear[this.itemSlot].id}`;
      }
      return '#';
    },
    getIcon() {
      if (this.hasItemEquipped()) {
        return `https://eq2reappapi.mooo.com/icons/${this.gear[this.itemSlot].iconId}.png`;
      }
      return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    },
    getName() {
      if (this.hasItemEquipped()) {
        return this.gear[this.itemSlot].name;
      }
      return `${this.title} (empty)`;
    },
    hasCboc() {
      if (this.hasItemEquipped()) {
        return this.gear[this.itemSlot].cboc && !this.gear[this.itemSlot].isWeapon;
      }
      return false;
    },
    getUpgradeLink() {
      if (this.itemSlot === 'event_slot' || !this.hasItemEquipped()) {
        return null;
      }

      let slot = this.itemSlot.charAt(0).toUpperCase() + this.itemSlot.slice(1);
      if (slot.startsWith('Activate')) {
        slot = 'Charm';
      }
      else if (slot.startsWith('Ears')) {
        slot = 'Ear';
      }
      else if (slot.endsWith('_ring')) {
        slot = 'Finger';
      }
      else if (slot.endsWith('_wrist')) {
        slot = 'Wrist';
      }
      return `https://u.eq2wire.com/soe/item_search_link/-1/-1/-1/-1/resolve/-1/-1/${slot}/-1/-1/-1/-1/-1/-1/-1/-1/-1/-1/-1/-1/${this.getResolveAmount() + 1}/-1/-1`;
    },
  },
  methods: {
    hasItemEquipped() {
      return Object.prototype.hasOwnProperty.call(this.gear, this.itemSlot) && this.gear[this.itemSlot].id;
    },
    getResolveAmount() {
      if (this.hasItemEquipped()) {
        return this.gear[this.itemSlot].amount ?? 0;
      }
      return 0;
    },
    getPurpleCount() {
      if (this.hasItemEquipped()) {
        return this.gear[this.itemSlot].adorns.purple?.count ?? 0;
      }
      return 0;
    },
  }
}
</script>

<style lang="scss" scoped>
.hidden {
  display: none;
}

td {
  padding: 0.1em 1em 0.1em 0.3em;
}

.gear-slot {
  display: flex;
  align-items: center;

  img {
    width: 32px;
    height: 32px;
    border: 1px solid #ddd;
    background-color: #eee;

    &[src^="data"] {
      visibility: hidden;
    }

    &.cboc {
      border: 2px solid #c75a00;
      border-radius: 4px;
      background-color: #83300046;
    }
  }
}

ul.slots-purple {
  margin: 0 10px 0 12px;
  padding: 0;
  list-style: none;

  li {
    margin: -10px;
    padding: 0;

    a {
      display: inline-block;
      width: 10px;
      height: 10px;
      font-size: 0;
      white-space: nowrap;
      overflow: hidden;
      outline: none;
      box-sizing: border-box;
      border: 1px solid #650072;
      border-radius: 50%;
      background-color: #cf9bd6;
      cursor: default;
    }
  }
}

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.upgrade-link {
  margin-left: 0.3em;
  opacity: 0.75;
}

.mythical {
  color: rgb(129, 0, 155);
}
.celestial {
  color: rgb(0, 155, 0);
}
.fabled {
  color: rgb(189, 0, 0);
}
.legendary {
  color: rgb(221, 181, 0);
}
.ethereal {
  color: rgb(221, 99, 0);
}
.mastercrafted {
  color: rgb(0, 162, 221);
}

</style>
